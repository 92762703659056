export const firebaseWebSDKConfig = {
    apiKey: "AIzaSyCL-55K71NaLh-2LZvefGkXeOrGysaI5Lk",
    authDomain: "elite-5322a.firebaseapp.com",
    databaseURL: "https://elite-5322a.firebaseio.com",
    projectId: "elite-5322a",
    storageBucket: "elite-5322a.appspot.com",
    messagingSenderId: "891476663838",
    appId: "1:891476663838:web:2dfba45315df0da1dcd80f"
};

export const authUrl = "https://auth.serenefire.com/signin.json";
export const adminAppName = "elite-production";
