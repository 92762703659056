import { db } from '../firebase/Firebase';
import { colPath } from '../firebase/FirestoreHelper';

export const NewsActionTypes = {
    GetNewses: 'GetNewses',
    GetNewsesDone: 'GetNewsesDone',
};

export const getNewses = () => {
    return async (dispatch, getState) => {
        try {
            let newsState = getState().newsState;
            let getNewsesCompletedInState = newsState.getNewsesCompleted;
            if (getNewsesCompletedInState === false) {
                return;
            }

            dispatch({
                type: NewsActionTypes.GetNewses,
            });

            let query = db.collection(colPath.newses).orderBy('updatedAt', 'desc');
            let querySnapshot = await query.limit(6).get();
            let newses = [];

            for (let i = 0; i < querySnapshot.size; i++) {
                let doc = querySnapshot.docs[i];
                let news = { id: doc.id, ...doc.data() };
                newses.push(news);
            }

            dispatch({
                type: NewsActionTypes.GetNewsesDone,
                newses: newses,
            });
        } catch (error) {
            dispatch({
                type: NewsActionTypes.GetNewsesDone,
                error: error,
            });
        }
    };
};
