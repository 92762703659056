import React from 'react';
import { Helmet } from 'react-helmet';
import { l10n } from '../i18n/Localization';

const NotFoundPage = () => {
  let content = (
    <div className="container mt-3">
      <h1 className="text-center">Page Not Found</h1>
      <p className="text-center">The specified file was not found on this website. Please check the URL for mistakes and try again.</p>
    </div>
  );

  return (
    <div className="NotFoundPage">
      <Helmet>
        <link rel="icon" type="image/x-icon" href="/favicon.ico" />
        <meta name="description" content="Page not found" />
        <title>Page Not Found - {l10n.eliteName}</title>
      </Helmet>
      {content}
    </div>
  );
};

export default NotFoundPage;
