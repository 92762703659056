import { AuthActionTypes } from '../actions/AuthAction';

const initState = {
  authActionCompleted: false,
  user: null,
  authActionError: null,
};

const AuthReducer = (state = initState, action) => {
  switch (action.type) {
    case AuthActionTypes.SignIn:
    case AuthActionTypes.SignOut:
      return {
        ...state,
        authActionCompleted: false,
      };
    case AuthActionTypes.AuthActionDone:
      if (action.error) {
        return {
          ...state,
          authActionCompleted: true,
          authActionError: action.error,
        };
      } else {
        return {
          ...state,
          authActionCompleted: true,
          user: action.user,
          authActionError: null,
        };
      }
    default:
      return state;
  }
};

export default AuthReducer;
