import AuthReducer from './AuthReducer';
import MgmtReducer from './MgmtReducer';
import NewsReducer from './NewsReducer';
import ProductReducer from './ProductReducer';
import VideoReducer from './VideoReducer';
import ArticleReducer from './ArticleReducer';
import { combineReducers } from 'redux';

const RootReducer = combineReducers({
  authState: AuthReducer,
  mgmtState: MgmtReducer,
  newsState: NewsReducer,
  productState: ProductReducer,
  videoState: VideoReducer,
  articleState: ArticleReducer,
});

export default RootReducer;
