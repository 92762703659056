import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import { firebaseWebSDKConfig } from "../config/config";

firebase.initializeApp(firebaseWebSDKConfig);

export const auth = firebase.auth();
export const db = firebase.firestore();
export const functions = firebase.functions();
