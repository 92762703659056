import { MgmtActionTypes } from '../actions/MgmtAction';

const initState = {
    grantPermissionsCompleted: true,
    grantPermissionsMessage: '',
    grantPermissionsError: null,
    createSitemapsCompleted: true,
    createSitemapsMessage: '',
    createSitemapsError: null,
};

const MgmtReducer = (state = initState, action) => {
    switch (action.type) {
        case MgmtActionTypes.GrantPermissions:
            return {
                ...state,
                grantPermissionsCompleted: false,
            };
        case MgmtActionTypes.GrantPermissionsDone:
            if (action.error) {
                return {
                    ...state,
                    grantPermissionsCompleted: true,
                    grantPermissionsError: action.error,
                };
            } else {
                return {
                    ...state,
                    grantPermissionsCompleted: true,
                    grantPermissionsMessage: action.message,
                    grantPermissionsError: null,
                }
            }
        case MgmtActionTypes.CreateSitemaps:
            return {
                ...state,
                createSitemapsCompleted: false,
            };
        case MgmtActionTypes.CreateSitemapsDone:
            if (action.error) {
                return {
                    ...state,
                    createSitemapsCompleted: true,
                    createSitemapsError: action.error,
                };
            } else {
                return {
                    ...state,
                    createSitemapsCompleted: true,
                    createSitemapsMessage: action.message,
                    createSitemapsError: null,
                };
            }
        default:
            return state;
    }
};

export default MgmtReducer;
