import { db } from '../firebase/Firebase';
import { colPath } from '../firebase/FirestoreHelper';

export const ProductActionTypes = {
    GetProducts: 'GetProducts',
    GetProductsDone: 'GetProductsDone',
};

export const getProducts = () => {
    return async (dispatch, getState) => {
        try {
            let productState = getState().productState;
            let getProductsCompletedInState = productState.getProductsCompleted;
            if (getProductsCompletedInState === false) {
                return;
            }

            dispatch({
                type: ProductActionTypes.GetProducts,
            });

            let query = db.collection(colPath.products).orderBy('name', 'asc');
            let querySnapshot = await query.limit(6).get();
            let products = [];

            for (let i = 0; i < querySnapshot.size; i++) {
                let doc = querySnapshot.docs[i];
                let product = { id: doc.id, ...doc.data() };
                products.push(product);
            }

            dispatch({
                type: ProductActionTypes.GetProductsDone,
                products: products,
            });
        } catch (error) {
            dispatch({
                type: ProductActionTypes.GetProductsDone,
                error: error,
            });
        }
    };
};
