import { VideoActionTypes } from '../actions/VideoAction';

const initState = {
  getVideosCompleted: true,
  videos: [],
  getVideosError: null,
  getVideoCompleted: true,
  video: null,
  getVideoError: null,
  writeVideoCompleted: true,
  writeVideoMessage: '',
  writeVideoDocId: '',
  writeVideoError: null,
};

const VideoReducer = (state = initState, action) => {
  switch (action.type) {
    case VideoActionTypes.GetVideos:
      return {
        ...state,
        getVideosCompleted: false,
      };
    case VideoActionTypes.GetVideosDone:
      if (action.error) {
        return {
          ...state,
          getVideosCompleted: true,
          getVideosError: action.error,
        };
      } else {
        return {
          ...state,
          getVideosCompleted: true,
          videos: [...state.videos, ...action.videos],
          getVideosError: null,
        };
      }
    case VideoActionTypes.GetVideo:
      return {
        ...state,
        getVideoCompleted: false,
      };
    case VideoActionTypes.GetVideoDone:
      if (action.error) {
        return {
          ...state,
          getVideoCompleted: true,
          getVideoError: action.error,
        }
      } else {
        return {
          ...state,
          getVideoCompleted: true,
          video: action.video,
          getVideoError: null,
        }
      }
    case VideoActionTypes.WriteVideo:
      return {
        ...state,
        writeVideoCompleted: false,
      };
    case VideoActionTypes.WriteVideoDone:
      if (action.error) {
        return {
          ...state,
          writeVideoCompleted: true,
          writeVideoError: action.error,
        }
      } else {
        return {
          ...state,
          writeVideoCompleted: true,
          writeVideoMessage: 'write-succeeded',
          writeVideoDocId: action.docId,
          writeVideoError: null,
        }
      }
    default:
      return state;
  }
};

export default VideoReducer;
