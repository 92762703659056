import React from 'react';
import moment from 'moment';

const NewsesListCard = (props) => {
  const { newses } = props;
  
  let newsesList = newses.length ? (newses.map(
    item => {
      return (
        <li className="list-group-item" key={item.id}>
          <div className="font-weight-bold">{item.content}</div>
          <div className="text-secondary">{moment(item.updatedAt.toDate()).calendar()}</div>
        </li>
      );
    })
  ) : null;

  return (
    <div className="card text-dark border-light">
      <div className="card-header">News</div>
      <ul className="list-group list-group-flush">
        {newsesList}
      </ul>
    </div>
  );
};

export default NewsesListCard;
