import stripHtml from "string-strip-html";
import { db } from '../firebase/Firebase';
import { colPath } from '../firebase/FirestoreHelper';

export const ArticleActionTypes = {
  GetArticles: 'GetArticles',
  GetArticlesDone: 'GetArticlesDone',
  GetArticle: 'GetArticle',
  GetArticleDone: 'GetArticleDone',
};

export const getArticles = (lastArticleId) => {
  return async (dispatch, getState) => {
    try {
      let articleState = getState().articleState;
      let getArticlesCompletedInState = articleState.getArticlesCompleted;
      if (getArticlesCompletedInState === false) {
        return;
      }

      dispatch({
        type: ArticleActionTypes.GetArticles,
      });

      let query = db.collection(colPath.articles.motivation).orderBy('updatedAt', 'desc');

      if (lastArticleId) {
        let docRef = db.doc(`${colPath.articles.motivation}/${lastArticleId}`);
        let docLastArticle = await docRef.get();

        if (docLastArticle.exists) {
          query = query.startAfter(docLastArticle);
        }
      }

      let querySnapshot = await query.limit(12).get();
      let articles = [];

      for (let i = 0; i < querySnapshot.size; i++) {
        let doc = querySnapshot.docs[i];
        let article = { id: doc.id, ...doc.data() };
        article.summary = stripHtml(article.content).substring(0, 80);
        article.url = `/motivation/articles/${article.id}`;
        articles.push(article);
      }

      dispatch({
        type: ArticleActionTypes.GetArticlesDone,
        articles: articles,
      });
    } catch (error) {
      dispatch({
        type: ArticleActionTypes.GetArticlesDone,
        error: error,
      });
    }
  };
};

export const getArticle = (articleId) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: ArticleActionTypes.GetArticle,
      });

      let docRef = db.doc(`${colPath.articles.motivation}/${articleId}`);
      let doc = await docRef.get();
      if (doc.exists) {
        let article = { id: doc.id, ...doc.data() };

        dispatch({
          type: ArticleActionTypes.GetArticleDone,
          article: article,
        });
      } else {
        let error = new Error('article does not exist');

        dispatch({
          type: ArticleActionTypes.GetArticleDone,
          error: error,
        });
      }
    } catch (error) {
      dispatch({
        type: ArticleActionTypes.GetArticleDone,
        error: error,
      });
    }
  };
};
