import React from 'react';

const Footer = () => {
  return (
    <div className="Footer">
      <footer>
        <div className="d-flex justify-content-center mt-3">
          <p className="text-muted">Google Play and the Google Play logo are trademarks of Google LLC.</p>
        </div>
        <div className="d-flex justify-content-center">
          <p className="text-muted">Copyright &copy; 2018-2022 Serenefire</p>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
