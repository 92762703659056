import { ArticleActionTypes } from '../actions/ArticleAction';

const initState = {
  getArticlesCompleted: true,
  articles: [],
  getArticlesError: null,
  getArticleCompleted: true,
  article: null,
  getArticleError: null,
};

const ArticleReducer = (state = initState, action) => {
  switch (action.type) {
    case ArticleActionTypes.GetArticles:
      return {
        ...state,
        getArticlesCompleted: false,
      };
    case ArticleActionTypes.GetArticlesDone:
      if (action.error) {
        return {
          ...state,
          getArticlesCompleted: true,
          getArticlesError: action.error,
        };
      } else {
        return {
          ...state,
          getArticlesCompleted: true,
          articles: [...state.articles, ...action.articles],
          getArticlesError: null,
        };
      }
    case ArticleActionTypes.GetArticle:
      return {
        ...state,
        getArticleCompleted: false,
      };
    case ArticleActionTypes.GetArticleDone:
      if (action.error) {
        return {
          ...state,
          getArticleCompleted: true,
          getArticleError: action.error,
        }
      } else {
        return {
          ...state,
          getArticleCompleted: true,
          article: action.article,
          getArticleError: null,
        }
      }
    default:
      return state;
  }
};

export default ArticleReducer;
