const en = {
    serenefireName: 'Serenefire',
    serenefireSlogan: 'Enhance humans. Improve the world.',
    eliteName: 'Serenefire Elite',
    eliteSlogan: 'Make me better!',
    motivationName: 'Elite Motivation',
    motivationSlogan: 'Get motivated everyday!',
};

export const l10n = en;
