import { NewsActionTypes } from '../actions/NewsAction';

const initState = {
  getNewsesCompleted: true,
  newses: [],
  getNewsesError: null,
};

const NewsReducer = (state = initState, action) => {
  switch (action.type) {
    case NewsActionTypes.GetNewses:
      return {
        ...state,
        getNewsesCompleted: false,
      };
    case NewsActionTypes.GetNewsesDone:
      if (action.error) {
        return {
          ...state,
          getNewsesCompleted: true,
          getNewsesError: action.error,
        };
      } else {
        return {
          ...state,
          getNewsesCompleted: true,
          newses: action.newses,
          getNewsesError: null,
        };
      }
    default:
      return state;
  }
};

export default NewsReducer;
