import React from 'react';
import { Link } from 'react-router-dom';

const ArticleCard = (props) => {
  const { article } = props;

  return (
    <div className="card bg-dark text-white border-light h-100">
      <div className="card-body">
        <h5 className="card-title">{article.title}</h5>
        <p className="card-text">{article.summary}</p>
      </div>
      <div className="card-footer">
        <Link className="btn btn-success float-right" to={article.url}>Read</Link>
      </div>
    </div>
  );
};

export default ArticleCard;
