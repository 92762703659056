import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import queryString from 'query-string';
import { getVideos } from '../../actions/VideoAction';
import { getProducts } from '../../actions/ProductAction';
import { l10n } from '../../i18n/Localization';
import VideoCard from '../VideoCard';
import ProductCard from '../ProductCard';

class IndexPage extends Component {
  componentDidMount() {
    if (this.props.videos.length === 0) {
      let lastVideoId = this.props.lastVideoId;
      this.props.getVideos(lastVideoId);
    }

    if (this.props.products.length === 0) {
      this.props.getProducts();
    }
  }

  handleClickLoad = () => {
    let lastVideoId = this.props.lastVideoId;
    this.props.getVideos(lastVideoId);
  }

  render() {
    const { getVideosCompleted, videos, products } = this.props;

    let videoCardDivs = videos.length ? (
      videos.map(video => {
        return (
          <div className="col-12 col-md-6 col-lg-3 mb-3" key={video.id}>
            <VideoCard video={video} />
          </div>
        );
      })
    ) : null;

    let productCardDivs = products.length ? (
      products.map(product => {
        return (
          <div className="col-12 col-md-6 col-lg-3 mb-3" key={product.id}>
            <ProductCard product={product} showWebsiteLink={false} />
          </div>
        );
      })
    ) : null;

    let btnLoadContent = getVideosCompleted ? 'load more' : 'loading';

    let content = (
      <div className="container mt-3">
        <div className="row">
          {videoCardDivs}
        </div>
        <div className="d-flex justify-content-center mb-3">
          <button className="btn btn-primary" type="button" onClick={this.handleClickLoad} disabled={!getVideosCompleted} >{btnLoadContent}</button>
        </div>
        <div className="row">
          {productCardDivs}
        </div>
      </div>
    );

    return (
      <div className="IndexPage">
        <Helmet>
          <link rel="icon" type="image/x-icon" href="/favicons/favicon_motivation.ico" />
          <meta name="description" content={l10n.motivationName + ', ' + l10n.motivationSlogan} />
          <title>{l10n.motivationName} - {l10n.motivationSlogan}</title>
        </Helmet>
        {content}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let lastVideoId;
  let videosInState = state.videoState.videos;

  if (videosInState.length > 0) {
    let lastVideo = videosInState[videosInState.length - 1];
    lastVideoId = lastVideo.id;
  } else {
    lastVideoId = queryString.parse(ownProps.location.search).lastVideoId;
  }

  return {
    lastVideoId: lastVideoId,
    getVideosCompleted: state.videoState.getVideosCompleted,
    videos: state.videoState.videos,
    products: state.productState.products,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    getVideos: (lastVideoId) => {
      dispatch(getVideos(lastVideoId));
    },
    getProducts: () => {
      dispatch(getProducts());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(IndexPage);
