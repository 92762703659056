import { functions } from '../firebase/Firebase';

export const MgmtActionTypes = {
    GrantPermissions: 'GrantPermissions',
    GrantPermissionsDone: 'GrantPermissionsDone',
    CreateSitemaps: 'CreateSitemaps',
    CreateSitemapsDone: 'CreateSitemapsDone',
};

export const grantPermissions = (data) => {
    return async (dispatch, getState) => {
        try {
            let authState = getState().authState;
            let grantPermissionsCompletedInState = authState.grantPermissionsCompleted;
            if (grantPermissionsCompletedInState === false) {
                return;
            }

            dispatch({
                type: MgmtActionTypes.GrantPermissions,
            });

            let grantFunc = functions.httpsCallable('grantPermissions');
            let result = await grantFunc(data);

            dispatch({
                type: MgmtActionTypes.GrantPermissionsDone,
                message: result.data.message,
            });
        } catch (error) {
            dispatch({
                type: MgmtActionTypes.GrantPermissionsDone,
                error: error,
            });
        }
    }
};

export const createSitemaps = (data) => {
    return async (dispatch, getState) => {
        try {
            let mgmtState = getState().mgmtState;
            let createSitemapsCompletedInState = mgmtState.createSitemapsCompleted;
            if (createSitemapsCompletedInState === false) {
                return;
            }

            dispatch({
                type: MgmtActionTypes.CreateSitemaps,
            });

            let createFunc = functions.httpsCallable('createSitemaps');
            let result = await createFunc();

            dispatch({
                type: MgmtActionTypes.CreateSitemapsDone,
                message: result.data.message,
            });
        } catch (error) {
            dispatch({
                type: MgmtActionTypes.CreateSitemapsDone,
                error: error,
            });
        }
    }
};
