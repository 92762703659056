import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import queryString from 'query-string';
import { getArticles } from '../../actions/ArticleAction';
import { getProducts } from '../../actions/ProductAction';
import { l10n } from '../../i18n/Localization';
import ArticleCard from '../ArticleCard';
import ProductCard from '../ProductCard';

class ArticlesPage extends Component {
  componentDidMount() {
    if (this.props.articles.length === 0) {
      let lastArticleId = this.props.lastArticleId;
      this.props.getArticles(lastArticleId);
    }

    if (this.props.products.length === 0) {
      this.props.getProducts();
    }
  }

  handleClickLoad = () => {
    let lastArticleId = this.props.lastArticleId;
    this.props.getArticles(lastArticleId);
  }

  render() {
    const { getArticlesCompleted, articles, products } = this.props;

    let articleCardDivs = articles.length ? (
      articles.map(article => {
        return (
          <div className="col-12 col-md-6 col-lg-3 mb-3" key={article.id}>
            <ArticleCard article={article} />
          </div>
        );
      })
    ) : null;

    let productCardDivs = products.length ? (
      products.map(product => {
        return (
          <div className="col-12 col-md-6 col-lg-3 mb-3" key={product.id}>
            <ProductCard product={product} showWebsiteLink={false} />
          </div>
        );
      })
    ) : null;

    let btnLoadContent = getArticlesCompleted ? 'load more' : 'loading';

    let content = (
      <div className="container mt-3">
        <div className="row">
          {articleCardDivs}
        </div>
        <div className="d-flex justify-content-center mb-3">
          <button className="btn btn-primary" type="button" onClick={this.handleClickLoad} disabled={!getArticlesCompleted} >{btnLoadContent}</button>
        </div>
        <div className="row">
          {productCardDivs}
        </div>
      </div>
    );

    return (
      <div className="ArticlesPage">
        <Helmet>
          <link rel="icon" type="image/x-icon" href="/favicons/favicon_motivation.ico" />
          <meta name="description" content="Articles" />
          <title>Articles - {l10n.motivationName}</title>
        </Helmet>
        {content}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let lastArticleId;
  let articlesInState = state.articleState.articles;

  if (articlesInState.length > 0) {
    let lastArticle = articlesInState[articlesInState.length - 1];
    lastArticleId = lastArticle.id;
  } else {
    lastArticleId = queryString.parse(ownProps.location.search).lastArticleId;
  }

  return {
    lastArticleId: lastArticleId,
    getArticlesCompleted: state.articleState.getArticlesCompleted,
    articles: state.articleState.articles,
    products: state.productState.products,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    getArticles: (lastArticleId) => {
      dispatch(getArticles(lastArticleId));
    },
    getProducts: () => {
      dispatch(getProducts());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ArticlesPage);
