import { ProductActionTypes } from '../actions/ProductAction';

const initState = {
  getProductsCompleted: true,
  products: [],
  getProductsError: null,
};

const ProductReducer = (state = initState, action) => {
  switch (action.type) {
    case ProductActionTypes.GetProducts:
      return {
        ...state,
        getProductsCompleted: false,
      };
    case ProductActionTypes.GetProductsDone:
      if (action.error) {
        return {
          ...state,
          getProductsCompleted: true,
          getProductsError: action.error,
        }
      } else {
        return {
          ...state,
          getProductsCompleted: true,
          products: action.products,
          getProductsError: null,
        };
      }
    default:
      return state;
  }
};

export default ProductReducer;
