import React from 'react';
import { Link } from 'react-router-dom';

const VideoCard = (props) => {
  const { video } = props;

  return (
    <div className="card bg-dark text-white border-light h-100">
      <div className="embed-responsive embed-responsive-16by9">
        <img className="card-img-top embed-responsive-item img-cover" src={video.thumbnail} alt="thumbnail" />
      </div>
      <div className="card-body">
        <h5 className="card-title">{video.title}</h5>
        <h6 className="card-subtitle text-muted">source: YouTube</h6>
      </div>
      <div className="card-footer">
        <Link className="btn btn-success float-right" to={video.watchURL}>Watch</Link>
      </div>
    </div>
  );
};

export default VideoCard;
